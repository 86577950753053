<template>
    <page title="请假列表" class="approve-list-page" :hasBack="false" textAlign="center">

            <form action="/" class="search">
                <van-search @search="onSearch"  @cancel="onSearch" name="keyword" v-model="searchValue"  placeholder="请输入搜索吧..." />
            </form>
            <van-list
                @load="onLoad"
                v-model:loading="loading"
                :finished="finished"
                finished-text="已经到底了"
                :offset="50"
                :immediate-check="false"
                v-if="!approveListHasLoad || approveList.length"
            >
                <div class="approve-item" v-for="item in approveList" @click="viewDetail(item.id)" :key="item.id">
                    <div class="avatar-wrapper">
                        <van-image v-if="item.photo" class="vant-img" fit="cover" :src="item.photo" />
                        <img class="default-img" v-else :src="require('@/assets/images/user/default_avatar.png')" alt="" />
                        <!--                            <img class="avatar" :src="item.photo" alt="" />-->
                    </div>
                    <div class="detail">
                        <div class="des-item">
                            <span class="name">{{ item.studentName }}</span>
                            <span class="class">{{ item.className }}</span>
                        </div>
                        <div class="des-item">
                            <span class="label">开始时间:</span>
                            <span class="des">{{ item.startTime }}</span>
                        </div>
                        <div class="des-item">
                            <span class="label">结束时间:</span>
                            <span class="des">{{ item.endTime }}</span>
                        </div>
                        <div class="des-item reason">
                            <span class="label">请假事由:</span>
                            <span class="des">{{ item.reason }}</span>
                        </div>
                    </div>
                </div>
            </van-list>
            <div v-else class="empty-wrapper">
                <empty />
            </div>
    </page>
</template>
<script>
    import Page from '@/components/page/Page';
    import Empty from '@/components/empty/Empty';
    import { Search, List, Image as VanImage, Form  } from 'vant';
    import { ref } from 'vue';
    import commonRequest from '@/services';
    import { useRouter } from 'vue-router';

    export default {
        name: 'ApproveList',
        components: {
            [Page.name]: Page,
            [Search.name]: Search,
            [List.name]: List,
            [Empty.name]: Empty,
            [VanImage.name]: VanImage,
            [Form.name]: Form,
        },
        setup() {
            const router = useRouter();
            const approveList = ref([
                // { id: 1, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', className: '高一班',
                //     startTime: '2021-10-13   15:16:25', endTime: '2021-10-13   15:16:25',
                //     reason: '【事假】家中有事，望\n' +
                //         '  郭郭老师批准。郭老师批准郭老师批准'
                // },
                // { id: 1, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', className: '高一班',
                //     startTime: '2021-10-13   15:16:25', endTime: '2021-10-13   15:16:25',
                //     reason: '【事假】家中有事，望\n' +
                //         '  郭郭老师批准。郭老师批准郭老师批准'
                // },
                // { id: 1, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', className: '高一班',
                //     startTime: '2021-10-13   15:16:25', endTime: '2021-10-13   15:16:25',
                //     reason: '【事假】家中有事，望\n' +
                //         '  郭郭老师批准。郭老师批准郭老师批准'
                // },
                // { id: 1, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', className: '高一班',
                //     startTime: '2021-10-13   15:16:25', endTime: '2021-10-13   15:16:25',
                //     reason: '【事假】家中有事，望\n' +
                //         '  郭郭老师批准。郭老师批准郭老师批准'
                // }
            ]);
            const approveListHasLoad = ref(false);
            const searchValue = ref('');
            const loading = ref(false);
            const finished = ref(false);
            const pageNum = ref(0);
            const initPage = 15;
            const onLoad = () => {
                console.log('onLoad')
                pageNum.value++;
                commonRequest({
                    action: 12002,
                    status: 2,
                    pageNum: pageNum.value,
                    pageSize: initPage,
                    keyword: searchValue.value,
                }).then((res) => {
                    approveListHasLoad.value = true;
                    if (res && res.rows) {
                        loading.value = false;
                        approveList.value = approveList.value.concat(res.rows);
                        if (res.rows.length < initPage) {
                            finished.value = true;
                        }
                    }
                });
            };
            onLoad();

            const viewDetail = (id) => {
                router.push({
                    path: '/approveDetailSecurity',
                    query: {id}
                })
            };

            const onSearch = () => {
                pageNum.value = 0;
                finished.value = false;
                approveListHasLoad.value = false;
                if (!searchValue.value && !approveList.value.length) {
                    approveList.value = [];
                    onLoad()
                } else {
                    approveList.value = [];
                }
            };

            return {
                approveList,
                approveListHasLoad,
                searchValue,
                finished,
                loading,
                onLoad,
                viewDetail,
                onSearch
            };
        },
    };
</script>
<style lang="less" scoped>
    .approve-list-page {
        padding-bottom: 20px;
        box-sizing: border-box;
        /deep/ .nav-page {
            display: flex;
            flex-direction: column;
        }
        /deep/ .van-list{
            flex: 1;
        }
    }
    .search {
        flex-shrink: 0;
    }
    .approve-item {
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 5px;
        height: 151px;
        margin-bottom: 10px;
    }
    .avatar-wrapper {
        height: 111px;
        background: #e2e2e2;
        border-radius: 5px;
        position: relative;
        width: 75px;
        margin-right: 10px;
        .vant-img {
            width: 100%;
            height: 100%;
        }
        .default-img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }
    }
    .empty-wrapper {
        height: 350px;
    }
    .detail {
        flex: 1;
        .des-item {
            margin-bottom: 10px;
        }
        .name,
        .class {
            font-size: 15px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            line-height: 17px;
            color: #333333;
        }
        .name {
            margin-right: 10px;
        }
        .label {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #333333;
            margin-right: 5px;
        }
        .des {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: 400;
            color: #333333;
        }
        .reason {
            display: flex;
            padding-right: 15px;
            .label {
                flex-shrink: 0;
            }
            .des {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
</style>
